define(['app'], function (app) {

  const loyaltyRedeemPointsCard = function() {

    const component = {};

    component.config = {
      dataAttributes: {
        pointsRedeemed: 'data-loyalty-points-redeemed',
      },
      button: '.loyaltyRedeemPointsCards_card_cta',
    }

    component.init = (element) => {
      component.element = element;
      component.pointsRedeemed = component.element.getAttribute(component.config.dataAttributes.pointsRedeemed);
      component.button = component.element.querySelector(component.config.button);
      component.element.addEventListener('submit', component.submit);

      return component;
    };

    component.submit = () => {
      app.publish('tracking/record', 'Account', 'Rewards', `Redeem ${component.pointsRedeemed}`);
      component.button && (component.button.disabled = true);
    }

    return component;

  };

  return loyaltyRedeemPointsCard;
});
